// TextTruncation.js

/**
 * Smartly truncates text to a specified length while keeping words intact
 * @param {string} text - The text to truncate
 * @param {number} maxLength - Maximum length of the truncated text including ellipsis
 * @param {object} options - Additional options
 * @param {string} options.ellipsis - Custom ellipsis string (default: "...")
 * @param {boolean} options.respectWordBoundary - Whether to respect word boundaries (default: true)
 * @returns {string} Truncated text
 */
export const truncateText = (text, maxLength, options = {}) => {
    const {
      ellipsis = "...",
      respectWordBoundary = true
    } = options;
  
    // Return original text if it's shorter than max length
    if (!text || text.length <= maxLength) {
      return text;
    }
  
    // Account for ellipsis in max length
    const targetLength = maxLength - ellipsis.length;
  
    if (!respectWordBoundary) {
      return text.slice(0, targetLength) + ellipsis;
    }
  
    // Find the last space before targetLength
    const truncated = text.slice(0, targetLength);
    const lastSpaceIndex = truncated.lastIndexOf(" ");
  
    // If no space found or space is at the start, truncate at exact length
    if (lastSpaceIndex === -1 || lastSpaceIndex === 0) {
      return truncated + ellipsis;
    }
  
    // Truncate at last word boundary
    return text.slice(0, lastSpaceIndex) + ellipsis;
  };
  
  /**
   * Truncates HTML text while preserving tags
   * @param {string} htmlText - HTML text to truncate
   * @param {number} maxLength - Maximum length of the visible text
   * @param {object} options - Additional options
   * @returns {string} Truncated HTML text
   */
  export const truncateHtml = (htmlText, maxLength, options = {}) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlText;
    const textContent = tempDiv.textContent;
    
    // If text is already shorter than max length, return original
    if (textContent.length <= maxLength) {
      return htmlText;
    }
  
    const truncatedText = truncateText(textContent, maxLength, options);
    return truncatedText;
  };
  
  /**
   * Returns both full and truncated versions of text
   * @param {string} text - The text to process
   * @param {number} maxLength - Maximum length for truncated version
   * @param {object} options - Additional options
   * @returns {object} Object containing both versions
   */
  export const getTruncatedVersions = (text, maxLength, options = {}) => {
    return {
      full: text,
      truncated: truncateText(text, maxLength, options),
      isTruncated: text.length > maxLength
    };
  };
  
  export default {
    truncateText,
    truncateHtml,
    getTruncatedVersions
  };