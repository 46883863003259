import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Database, Search, X, ArrowRight } from 'lucide-react';
import { search, getLhumosStructure } from './APImodules';
import { extractCleanText } from './TextContentProcessor';
import LhumosSpaces from '../spaces.json';

const SearchResults = ({ query, onClose, spaceColor1, spaceColor2, spaceColor3 }) => {
  const [searchResults, setSearchResults] = useState({ results: [], count: 0, from: 0, totalSize: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [lhumosStructure, setLhumosStructure] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const searchData = await search(query, 'dataset');
        const structure = await getLhumosStructure();
        setSearchResults(searchData || { results: [], count: 0, from: 0, totalSize: 0 });
        setLhumosStructure(structure);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults({ results: [], count: 0, from: 0, totalSize: 0 });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [query]);

  const findDatasetInStructure = (datasetId, structure) => {
    if (!Array.isArray(structure)) return [];
    const spaces = [];
    for (const space of structure) {
      const collectionInfo = findDatasetInCollections(datasetId, space.children);
      if (collectionInfo) {
        const spaceDetails = LhumosSpaces.find(s => s.id === space.id);
        spaces.push({ 
          ...collectionInfo, 
          spaceName: space.name, 
          spaceId: space.id,
          spaceDetails
        });
      }
    }
    return spaces;
  };

  const findDatasetInCollections = (datasetId, collections) => {
    if (!Array.isArray(collections)) return null;
    for (const collection of collections) {
      if (Array.isArray(collection.children)) {
        const datasetIndex = collection.children.findIndex(child => child.id === datasetId);
        if (datasetIndex !== -1) {
          return { collectionId: collection.id, datasetIndex, collection };
        }
        const nestedResult = findDatasetInCollections(datasetId, collection.children);
        if (nestedResult) return nestedResult;
      }
    }
    return null;
  };

  const getSpaceIndex = (spaceId) => LhumosSpaces.findIndex(space => space.id === spaceId);

  const handleResultClick = (result, spaceInfo) => {
    if (!lhumosStructure) return;
    const spaceIndex = getSpaceIndex(spaceInfo.spaceId);
    if (spaceIndex === -1) return;
    
    const link = `/player/${spaceIndex}/${spaceInfo.datasetIndex}/${spaceInfo.collectionId}/${result.id}`;
    onClose();
    navigate(link);
  };

  const filteredResults = searchResults.results.filter(result => 
    findDatasetInStructure(result.id, lhumosStructure).length > 0
  );

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      onClick={onClose}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(2px)'
      }}
    >
      <div className="min-h-full px-4 py-8">
        <div
          className="rounded-xl shadow-lg p-6 relative w-4/5 mx-auto"
          style={{
            backgroundImage: `linear-gradient(to bottom, ${spaceColor2}, rgba(0, 0, 0, 0.8))`
          }}
          onClick={e => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white/90 p-1 rounded-full hover:bg-white/10"
          >
            <X size={24} />
          </button>
          
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2">
              <Search className="text-white" size={24} />
              <h2 className="text-2xl text-white font-bold">Search Results</h2>
            </div>
            <span className="flex items-center gap-1 bg-white/25 backdrop-blur-sm px-3 py-1.5 rounded text-white">
              <Database size={18} />
              {filteredResults.length} Results
            </span>
          </div>

          {isLoading ? (
            <div className="text-white text-center py-8">Loading results...</div>
          ) : (
            <div className="space-y-2">
              {filteredResults.map((result) => {
                const spaces = findDatasetInStructure(result.id, lhumosStructure);
                const cleanDescription = extractCleanText(result.description);
                
                return (
                  <div
                    key={result.id}
                    className="backdrop-blur-sm rounded-lg overflow-hidden hover:bg-white/5 transition-colors"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderLeft: `4px solid ${spaceColor3}`
                    }}
                  >
                    <div className="p-3">
                      <div className="flex gap-4">
                        <div className="relative w-36 h-20 flex-shrink-0 rounded-lg overflow-hidden">
                          <div
                            className="absolute inset-0 bg-cover bg-center"
                            style={{
                              backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${result.thumbnail}/blob")`
                            }}
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent" />
                        </div>

                        <div className="flex-grow min-w-0">
                          <h4 className="text-lg text-white font-semibold mb-1 truncate">
                            {result.name}
                          </h4>
                          {cleanDescription && (
                            <p className="text-white/80 text-sm leading-relaxed line-clamp-2 mb-2">
                              {cleanDescription}
                            </p>
                          )}
                          <div className="flex flex-wrap gap-2">
                            {spaces.map((spaceInfo, idx) => (
                              <button
                                key={`${result.id}-${idx}`}
                                onClick={() => handleResultClick(result, spaceInfo)}
                                className="flex items-center px-3 py-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors text-white text-sm"
                                style={{
                                  borderLeft: `3px solid ${spaceInfo.spaceDetails.accent}`
                                }}
                              >
                                <img 
                                  src={spaceInfo.spaceDetails.iconurl}
                                  alt={spaceInfo.spaceName}
                                  className="w-5 h-5 rounded-full mr-2"
                                />
                                View in {spaceInfo.spaceName}
                                <ArrowRight size={16} className="ml-2" />
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;