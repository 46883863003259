import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Database, Globe2, Link as LinkIcon, ChevronDown } from "lucide-react";
import Footer from "./components/Footer";
import CollectionContainer from "./components/CollectionContainer";
import Breadcrumbs from "./components/Breadcrumbs";
import LhumosSpaces from "./spaces.json";
import LoadingScreen from "./components/LoadingScreen";
import { getDatasetsBySpaceId, getCollectionsBySpaceId, getSpaceById } from "./components/APImodules.js";

function SingleSpace() {
  const [isLoading, setIsLoading] = useState(true);
  const [space, setSpace] = useState(null);
  const [collections, setCollections] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [expandedCollectionId, setExpandedCollectionId] = useState(null);
  const [spacesStats, setSpacesStats] = useState({ collections: 0, datasets: 0 });

  const { spaceId } = useParams();
  const SPACE_INDEX = spaceId;
  const SPACE_ID = LhumosSpaces[SPACE_INDEX]?.id;
  const spaceDetails = useMemo(() => LhumosSpaces[SPACE_INDEX], [SPACE_INDEX]);

  const reorderCollections = (collections, order) => {
    const orderedCollections = order.map(id => collections.find(collection => collection.id === id)).filter(Boolean);
    const remainingCollections = collections.filter(collection => !order.includes(collection.id));
    return [...orderedCollections, ...remainingCollections];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [fetchedSpace, fetchedCollections, fetchedDatasets] = await Promise.all([
          getSpaceById(SPACE_ID),
          getCollectionsBySpaceId(SPACE_ID),
          getDatasetsBySpaceId(SPACE_ID)
        ]);

        setSpace(fetchedSpace);
        const collectionOrder = spaceDetails.collectionOrder || [];
        const orderedCollections = reorderCollections(fetchedCollections, collectionOrder);
        setCollections(orderedCollections);
        setDatasets(fetchedDatasets);

        // Get stats from cached structure
        try {
          const cached = localStorage.getItem("lhumosStructure");
          if (cached) {
            const { treeData } = JSON.parse(cached);
            const spaceNode = treeData.children.find(node => 
              node.name === fetchedSpace.name
            );
            
            if (spaceNode) {
              let collections = 0;
              let datasets = 0;
              const traverse = (n) => {
                if (n.type === "collection") collections++;
                if (n.type === "dataset") datasets++;
                if (n.children) n.children.forEach(traverse);
              };
              traverse(spaceNode);
              setSpacesStats({ collections, datasets });
            }
          }
        } catch (error) {
          console.error("Error loading cached stats:", error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    if (SPACE_ID) {
      fetchData();
      window.scrollTo(0, 0);
    }
  }, [SPACE_ID, spaceDetails]);

  if (isLoading || !spaceDetails) return <LoadingScreen />;

  return (
    <div className="min-h-screen relative overflow-hidden" style={{ backgroundImage: `linear-gradient(to bottom, ${spaceDetails.color1}, #000000)` }}>
      <style>
        {`
          .scroll-indicator {
            animation: bounceDown 2s infinite;
          }
          
          @keyframes bounceDown {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(8px);
            }
            60% {
              transform: translateY(4px);
            }
          }
        `}
      </style>

      <Breadcrumbs />
      
      {/* Hero Section */}
      <div className="bg-cover bg-center bg-fixed bg-no-repeat p-8 md:p-24 text-white relative" 
           style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${spaceDetails.bgurl})` }}>
        <div className="max-w-screen-xl mx-auto rounded-3xl md:p-8 backdrop-blur-xl relative">
          <div className="flex flex-col md:flex-row items-center gap-6">
            {/* Logo Section */}
            <div className="relative group">
              <div 
                className="absolute inset-0 bg-gradient-to-br from-white/20 to-white/0 rounded-xl blur-xl transition-all duration-300 group-hover:scale-110"
                style={{ backgroundColor: spaceDetails.accent }}
              />
              <img 
                src={spaceDetails.iconurl}
                alt={space?.name}
                className="w-24 h-24 md:w-32 md:h-32 rounded-xl relative border-2 transition-transform duration-300 group-hover:scale-105"
                style={{ borderColor: spaceDetails.accent }}
              />
            </div>

            {/* Content Section */}
            <div className="flex-1">
              <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                <h1 className="text-3xl md:text-4xl font-bold text-white">
                  {space?.name}
                </h1>
                {spaceDetails.website && (
                  <a 
                    href={spaceDetails.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-4 py-2 bg-white/10 hover:bg-white/20 rounded-full backdrop-blur-sm transition-colors"
                  >
                    <LinkIcon size={16} className="text-white" />
                    <span className="text-white text-sm">Website</span>
                  </a>
                )}
              </div>

              <p className="text-white/80 text-sm md:text-base my-4 line-clamp-3 md:line-clamp-none">
                {space?.description}
              </p>

              <div className="flex flex-wrap gap-4">
                <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full backdrop-blur-sm">
                  <Database size={16} className="text-white" />
                  <span className="text-white text-sm">{spacesStats.collections} Collections</span>
                </div>
                <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full backdrop-blur-sm">
                  <Globe2 size={16} className="text-white" />
                  <span className="text-white text-sm">{spacesStats.datasets} Videos</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Collections Section */}
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Collections</h2>
          <ChevronDown className="text-white/60 scroll-indicator" size={24} />
        </div>
        <div className="space-y-4">
          {collections.map((collection, idx) => (
            <CollectionContainer
              key={collection.id}
              name={collection.name}
              spaceIndex={SPACE_INDEX}
              index={idx}
              isExpanded={expandedCollectionId === collection.id}
              onToggleExpand={() => setExpandedCollectionId(expandedCollectionId === collection.id ? null : collection.id)}
              isTopLevel={true}
              spaceDetails={spaceDetails}
            >
              {collection}
            </CollectionContainer>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SingleSpace;