import React, { useState, useRef } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import LhumosSpaces from "../spaces.json";
import Login from "./Login";
import Backdrop from "./Backdrop";
import SearchResults from "./SearchResults";

import logo from "../img/logo.png";
import lens from "../img/zoom-lens.png";
import compass from "../img/compass.png";
import web from "../img/web.png";

function Navbar() {
  const [loginIsOpen, setLoginIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const searchInputRef = useRef(null);

  const location = useLocation();
  let match = matchPath({ path: "/spaces/:id" }, location.pathname);
  let SPACE_INDEX = match && match.params.id;

  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }

  const spaceColor1 = SPACE_INDEX
    ? LhumosSpaces[SPACE_INDEX]?.color1 || ""
    : "#4883b8";
  const spaceColor2 = SPACE_INDEX
    ? LhumosSpaces[SPACE_INDEX]?.color2 || ""
    : "#5190c6";
  const spaceColor3 = SPACE_INDEX
    ? LhumosSpaces[SPACE_INDEX]?.accent || ""
    : "#63a1ff";

  const navbarStyle = SPACE_INDEX ? { backgroundColor: spaceColor1 } : {};

  const handleSearch = () => {
    if (searchQuery.trim() !== "") {
      setShowSearchResults(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const closeSearchResults = () => {
    setShowSearchResults(false);
  };

  return (
    <header>
      {loginIsOpen && (
        <>
          <Login onClick={() => setLoginIsOpen(false)} />
          <Backdrop onClick={() => setLoginIsOpen(false)} />
        </>
      )}
      <nav
        className={`sticky top-0 flex ${
          SPACE_INDEX ? "justify-start" : "justify-center"
        }`}
        style={navbarStyle}
      >
        <div className="flex justify-between items-center w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mt-2">
            <Link to="/" className="text-white mr-6">
              <img src={logo} className="w-28" alt="logo" />
            </Link>
            <Link
              to="/spaces"
              className="flex items-center text-white no-underline"
            >
              <img src={web} alt="spaces" className="w-6 h-6 mr-2" />
              Spaces
            </Link>
            <Link
              to="/map"
              className="flex items-center text-white no-underline ml-4"
            >
              <img src={compass} alt="Map" className="w-6 h-6 mr-2" />
              Map
            </Link>
          </div>

          <div className="relative mt-2">
            <input
              ref={searchInputRef}
              type="text"
              className={`pl-10 pr-4 py-2 rounded-full focus:outline-none transition-all duration-300 ${
                isSearchFocused ? "w-64" : "w-48"
              }`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              style={{
                backgroundColor: spaceColor2,
                color: "white",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              }}
              placeholder="Search..."
            />
            <img
              src={lens}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 opacity-60"
              alt="search"
              onClick={handleSearch}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </nav>
      {showSearchResults && (
        <SearchResults
          query={searchQuery}
          onClose={closeSearchResults}
          spaceColor1={spaceColor1}
          spaceColor2={spaceColor2}
          spaceColor3={spaceColor3}
        />
      )}
    </header>
  );
}

export default Navbar;
