import React from "react";
import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";
import LhumosSpaces from "../spaces.json";
import logo from "../img/logo.png";

function Footer() {
  const location = useLocation();
  var match = matchPath({ path: "/spaces/:id" }, location.pathname);
  var SPACE_INDEX = match && match.params.id;
  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match && match.params.id;
  }

  const spaceDetails = SPACE_INDEX != null ? LhumosSpaces[SPACE_INDEX] : null;

  return (
    <footer className="bg-black">
      <div className="max-w-7xl mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="logo" className="h-6" />
            </Link>
            <span className="text-xs text-white/60 border-l border-white/20 pl-3">
              V 1.0
            </span>
          </div>

          {spaceDetails?.footerText && (
            <div className="flex-1 mx-8">
              <p className="text-xs text-white/60 text-center">
                {spaceDetails.footerText}
              </p>
            </div>
          )}

          {spaceDetails?.footerImage && (
            <div>
              <img
                src={spaceDetails.footerImage}
                alt="sponsor logo"
                className="h-6"
              />
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;