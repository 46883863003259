/**
 * TextContentProcessor.js
 * Utility for processing text content containing JSON patterns within markers
 */

// Known marker patterns
const MARKER_PATTERNS = {
  slides: {
    start: '[slides]',
    end: '[endslides]'
  },
  order: {
    start: '[order]',
    end: '[endorder]'
  },
  files: {
    start: '[files]',
    end: '[endfiles]'
  }
};

/**
 * Extracts clean text content by removing all marked JSON sections
 */
export const extractCleanText = (text) => {
  if (!text) return '';

  try {
    let cleanedText = text;

    // Remove all known marker patterns
    Object.values(MARKER_PATTERNS).forEach(({ start, end }) => {
      const regex = new RegExp(`\\${start}.*?\\${end}`, 'gs');
      cleanedText = cleanedText.replace(regex, '');
    });

    // Clean up whitespace and formatting
    return cleanedText
      .replace(/\s+/g, ' ')     // Normalize whitespace
      .replace(/\s+$/g, '')     // Remove trailing whitespace
      .replace(/^\s+/g, '')     // Remove leading whitespace
      .trim();
  } catch (error) {
    console.error('Error cleaning text content:', error);
    return text;
  }
};

/**
 * Extracts JSON content between specific markers
 */
export const extractJsonContent = (text, markerType) => {
  if (!text || !MARKER_PATTERNS[markerType]) return null;

  const { start, end } = MARKER_PATTERNS[markerType];

  try {
    const regex = new RegExp(`\\${start}(.*?)\\${end}`, 's');
    const match = text.match(regex);
    
    if (match && match[1]) {
      const jsonString = match[1].trim();
      return JSON.parse(jsonString);
    }
    
    return null;
  } catch (error) {
    console.error(`Error extracting ${markerType} content:`, error);
    return null;
  }
};

/**
 * Processes text content and returns all extracted components
 */
export const processTextContent = (text) => {
  if (!text) return {
    cleanText: '',
    jsonContent: {}
  };

  const jsonContent = {};
  
  // Extract JSON content for each known pattern
  Object.keys(MARKER_PATTERNS).forEach(patternKey => {
    jsonContent[patternKey] = extractJsonContent(text, patternKey);
  });

  return {
    cleanText: extractCleanText(text),
    jsonContent
  };
};

// Default export for easier imports
export default {
  extractCleanText,
  extractJsonContent,
  processTextContent,
  MARKER_PATTERNS
};