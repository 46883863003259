import React, { useEffect } from "react";
import LhumosSpaces from "../spaces.json";
import { Link } from "react-router-dom";
import { Database, X } from "lucide-react";
import { extractCleanText } from './TextContentProcessor';

function CollectionPopup({ collection, datasets, spaceIndex, onClose }) {
  const theme = LhumosSpaces[spaceIndex];

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Get clean descriptions
  const cleanCollectionDescription = extractCleanText(collection.description);

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      onClick={onClose}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(2px)'
      }}
    >
      <div className="min-h-full px-4 py-8">
        <div
          className="rounded-lg shadow-lg p-6 relative w-4/5 mx-auto"
          style={{
            backgroundColor: theme.color2,
            borderLeft: `4px solid ${theme.accent}`
          }}
          onClick={e => e.stopPropagation()}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white/90 p-1 rounded-full hover:bg-white/10"
          >
            <X size={24} />
          </button>
          
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl text-white font-bold">
              {collection.name || collection.collectionname}
            </h2>
            <span className="flex items-center gap-1 bg-white/25 backdrop-blur-sm px-3 py-1.5 rounded text-white">
              <Database size={18} />
              {datasets.length} Videos
            </span>
          </div>

          {cleanCollectionDescription && (
            <div 
              className="backdrop-blur-sm rounded-lg p-4 mb-6"
              style={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                borderLeft: `4px solid ${theme.accent}`
              }}
            >
              <p className="text-white/90 text-sm leading-relaxed">
                {cleanCollectionDescription}
              </p>
            </div>
          )}

          <div className="space-y-3">
            {datasets.map((dataset, index) => {
              // Clean each dataset's description using the imported function
              const cleanDatasetDescription = extractCleanText(dataset.description);
              
              return (
                <Link
                  key={dataset.id}
                  to={`/player/${spaceIndex}/${index}/${collection.id}/${dataset.id}`}
                  className="block no-underline"
                >
                  <div 
                    className="backdrop-blur-sm rounded-lg overflow-hidden hover:bg-white/20"
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.15)',
                      borderLeft: `4px solid ${theme.accent}`
                    }}
                  >
                    <div className="p-4 flex gap-4">
                      <div className="flex-shrink-0 flex items-center">
                        <span 
                          className="w-8 h-8 rounded-full flex items-center justify-center text-white text-sm"
                          style={{ backgroundColor: theme.accent }}
                        >
                          {index + 1}
                        </span>
                      </div>
                      <div className="flex-grow min-w-0">
                        <div className="flex justify-between items-start mb-2 gap-4">
                          <h4 className="text-lg text-white font-semibold truncate flex-grow">
                            {dataset.name}
                          </h4>
                        </div>
                        {cleanDatasetDescription && (
                          <p className="text-white/80 text-sm leading-relaxed line-clamp-2">
                            {cleanDatasetDescription}
                          </p>
                        )}
                      </div>

                      <div className="relative w-48 h-28 flex-shrink-0 rounded-lg overflow-hidden">
                        <div
                          className="absolute inset-0 bg-cover bg-center"
                          style={{
                            backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${dataset.thumbnail}/blob")`
                          }}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent" />
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionPopup;